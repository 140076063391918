.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 100px;
}

.header-title {
  font-family: 'pacificoregular';
  font-size: 3rem;
  color: #d291bc;
}
