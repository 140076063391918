.metronome {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 3px;
  padding-right: 3px;
}

.tempo-input {
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  flex-grow: 1;
}

.volume-control {
  width: 100%;
  // max-width: 300px;

  @media (min-width: 576px) {
    margin-left: 20px;
    max-width: 300px;
  }
}

.tempo-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 576px) {
    flex-direction: row;
  }
}

.start-button {
  margin-top: 5px;
  height: 60px;
}

.songs-list {
  margin-top: 20px;
}

.tap-button {
  margin-top: 5px;
}

.hidden {
  display: none;
}
