.song {
  div {
    display: inline;
  }
}

.full-width {
  width: 100%;
}

.song-name-parent {
  cursor: pointer;

  .song-name {
    display: block;
    transition: font-size 0.2s cubic-bezier(0.25, 1, 0.5, 1);
    transform-origin: left;

    &.selected-song {
      font-size: 35px !important;
    }
  }

  &:hover .song-name {
    font-size: 25px;
  }
}
